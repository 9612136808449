import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {
    Box,
    Card,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Slider,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';

const imageUrl = "https://fire.adlemx.ru/api/get_fires_predition"

function App() {
    const [img, setImg] = useState("default.png");
    const [accuracy, setAccuracy] = useState(8); // Initial accuracy value (6-12)
    const [selectedDate, setSelectedDate] = useState(dayjs("2022-08-08"));
    const [selectedRegion, setSelectedRegion] = useState("italy");
    const handleDateChange = (date) => {
        setSelectedDate(date);
        console.log(selectedDate.toISOString().trim())
    };

    const fetchImage = async () => {
        try {
            setImg(null)
            const res = await fetch(`${imageUrl}?date_iso=${selectedDate.toISOString().split("T")[0]}&accuracy=${accuracy}&region=${selectedRegion}`);
            const imageBlob = await res.blob();
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setImg(imageObjectURL);
        } catch (error) {
            console.error("Error fetching image:", error);
        }
    };

    useEffect(() => {
        // action on update of movies
    }, [accuracy]);
    const handleAccuracyChange = (_, newValue) => {
        setAccuracy(newValue);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // Full screen height
            }}>
                <Paper
                    elevation={3}
                    style={{
                        padding: "32px",
                        width: "95%", // Full screen width
                        margin: "1%",
                        height: "90%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        overflow: "auto", // Hide horizontal scrollbar
                    }}
                >
                    <Typography variant="h5" gutterBottom style={{marginTop: "1%"}}>
                        Прогнозирование пожаров в {selectedRegion === "russia" ? "России" : "Италии"}
                    </Typography>
                    <Grid container spacing={2} style={{height: "100%"}} columns={{xs: 12, sm: 4, md: 6}}>
                        <Grid item xs={12} sm={4} md={3}>

                            <div style={{marginBottom: "5%"}}/>
                            <DatePicker
                                sx={{width: "100%"}}
                                label="Выбор даты"
                                maxDate={dayjs().add(7, 'day')}
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <input {...params} />}
                            />
                            <Slider
                                value={accuracy}
                                onChange={handleAccuracyChange}
                                valueLabelDisplay="auto"
                                style={{marginTop: "5%"}}
                                min={4}
                                max={12}
                                step={1}
                            />
                            <Typography variant="body2" gutterBottom>
                                Точность модели: {accuracy}
                            </Typography>
                            <FormControl sx={{marginTop: "5%", width: "100%"}}>
                                <InputLabel id="region-select-helper-label">Регион</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="region-select-helper-label"
                                    label={"Регион"}
                                    value={selectedRegion}
                                    onChange={(e) => setSelectedRegion(e.target.value)}>
                                    <MenuItem value={"italy"}>Италия</MenuItem>
                                    <MenuItem value={"russia"}>Россия</MenuItem>
                                </Select>
                            </FormControl>
                            <Divider style={{margin: "5%"}}/>
                            <div style={{textAlign: "center"}}>
                                <Button variant="contained" color="primary" onClick={fetchImage}
                                        style={{margin: "auto"}}>
                                    Запустить
                                </Button>
                            </div>
                            <div style={{marginBottom: "5%"}}/>
                            <Typography variant="body2" textAlign="center">
                                На нашем сайте вы можете увидеть области предполагаемых пожаров в будущем, а также
                                посмотреть
                                карты с пожарами, произошедшими в прошлом.
                                В зависимости от цвета можно узнать вероятность пожара в определенной области на карте.
                                Согласно
                                легенде, чем светлее цвет, тем выше вероятность пожара на определенном участке.
                                Красными точками обозначены очаги уже произошедших пожаров.
                                С помощью изменения даты можно отслеживать предполагаемое распространение возможных
                                пожаров.
                                Все данные для предсказания автоматически берутся с https://open-meteo.com/ и
                                https://firms.modaps.eosdis.nasa.gov/map/<br/><br/>

                                Ограничение бесплатной версии сайта - 10 000 запросов в сутки<br/>
                                *в данном случае, запрос - это количество точек для забора данных. Чем выше выставлена
                                точность
                                предсказания, тем больше запросов делается за 1 раз
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <Card sx={{display: 'flex', alignItems: 'center', textAlign: "center"}}
                                  style={{width: "100%"}}>
                                {img !== null ? <>
                                        <img src={img} alt="Map" style={{height: "100%", width: "95%"}}/>
                                    </> :
                                    <Skeleton variant="rectangular" animation="wave" height="100%" width="100%">
                                        <img src={"default.png"} alt="Map" style={{height: "100%", width: "95%"}}/>
                                    </Skeleton>}
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </LocalizationProvider>
    );
}

export default App;
